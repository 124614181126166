<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import SegmentedControls from './components/SegmentedControls.vue'
import { SegmentedControlOption } from './interfaces/segmented-controls'
import { generateRandomShapes } from './misc/random-shapes'
import { COLORS } from './misc/colors'
import GlassContainer from './components/GlassContainer.vue'
import { HTML_MIME_TYPE, TEXT_MIME_TYPE } from './misc/data-formats'
import { Copy, FileImage } from 'lucide-vue-next'
import copy from 'copy-to-clipboard'
import { vTooltip } from 'floating-vue'
import { fileToBase64Async } from './misc/file-reader-utils'
import { Shape } from './interfaces/shapes'

const options: SegmentedControlOption[] = [
  {
    label: 'HTML',
    value: 'html'
  },
  {
    label: 'File',
    value: 'file'
  },
  {
    label: 'Text',
    value: 'text'
  }
]

const selectedOption = ref('html')

// #region textInput
const textInput = ref('')
const includeData = ref(true)
const textOutput = computed(() => {
  if (!textInput.value && !fileInput.value) {
    return ''
  }
  let outputString = ''
  if (includeData.value) {
    switch (selectedOption.value) {
      case 'html':
        outputString += 'data:' + HTML_MIME_TYPE + ';'
        break
      case 'text':
        outputString += 'data:' + TEXT_MIME_TYPE + ';'
        break
      case 'file':
        console.log('not here?')
        return fileInput.value
    }
  }
  outputString += 'base64,' + btoa(textInput.value)
  return outputString
})
//#endregion

//#region file input
const fileInput = ref('')
async function handleFile(e: Event) {
  const target = e.target as HTMLInputElement
  if (!target?.files) {
    return
  }
  const file = target.files[0]
  fileInput.value = (await fileToBase64Async(file)) as string
  console.log(fileInput.value)
}

async function handleDropFile(e: DragEvent) {
  const file = e.dataTransfer?.files?.item(0)
  e.preventDefault()
  if (!file) {
    return
  }
  fileInput.value = (await fileToBase64Async(file)) as string
}
//#endregion
const copied = ref(false)
const showTooltip = ref(false)
let timeout: ReturnType<typeof setTimeout> | undefined = undefined
function copyOutputToClipboard() {
  copy(textOutput.value)
  copied.value = true
  clearTimeout(timeout)
  timeout = setTimeout(() => (copied.value = false), 3000)
}

const canvas = ref<HTMLCanvasElement>()
const shapes: Shape[] = []
onMounted(() => {
  if (canvas.value) {
    canvas.value.width = document.body.clientWidth
    canvas.value.height = document.body.clientHeight
    shapes.push(...generateRandomShapes(canvas.value, 10, 100, COLORS))
  }
})
</script>
<template>
  <main class="h-dvh m-auto flex flex-col overflow-hidden bg-neutral-800">
    <canvas ref="canvas" class="w-full h-full absolute"></canvas>
    <div class="p-4 flex flex-col h-full gap-2 max-w-screen-lg mx-auto w-full">
      <SegmentedControls
        v-model="selectedOption"
        :options="options"
        class="flex-shrink-0 w-full"
      />
      <GlassContainer
        class="flex-grow flex-shrink-0 basis-0 w-full p-2 focus-within:ring-2 focus-within:ring-black/10 transition-all duration-1000 text-neutral-300"
      >
        <textarea
          v-if="selectedOption === 'html' || selectedOption === 'text'"
          v-model="textInput"
          class="bg-transparent outline-none text-lg resize-none w-full h-full"
          :placeholder="selectedOption === 'html' ? '<p>text...<p>' : 'text...'"
        ></textarea>
        <div
          v-else
          class="w-full h-full flex items-center justify-center"
          @drop="handleDropFile"
        >
          <label
            class="flex justify-center items-center flex-col gap-4 cursor-pointer"
            for="upload-image"
          >
            <FileImage class="h-20 w-20" />
            Upload an Image
          </label>
          <input
            class="h-0 w-0 opacity-0"
            type="file"
            id="upload-image"
            @change="handleFile"
          />
        </div>
      </GlassContainer>
      <GlassContainer
        class="flex-grow flex-shrink-0 basis-0 w-full p-2 text-neutral-300 relative overflow-auto break-all"
      >
        <button
          v-tooltip="{
            content: copied ? 'Copied!' : 'Copy',
            triggers: [],
            shown: showTooltip
          }"
          type="button"
          class="p-2 sub-glass transition-all duration-200 hover:bg-white/5 active:bg-white/10 absolute right-2 top-2"
          :class="textOutput ? 'visible opacity-100' : 'invisible opacity-0'"
          @click="copyOutputToClipboard"
          @mouseenter="(showTooltip = true), (copied = false)"
          @mouseleave="showTooltip = false"
        >
          <Copy />
        </button>
        {{ textOutput }}
      </GlassContainer>
    </div>
  </main>
</template>

<style scoped></style>
