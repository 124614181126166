<script setup lang="ts"></script>

<template>
  <div
    class="rounded-xl shadow-lg ring-1 ring-black/5 backdrop-blur-sm bg-white/10"
  >
    <slot></slot>
  </div>
</template>

<style scoped></style>
