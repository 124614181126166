import { ValueNoise } from 'value-noise-js'
import { Point, Shape } from '../interfaces/shapes'

export function generateRandomShapes(
  canvas: HTMLCanvasElement,
  averageNumberOfShapes: number,
  averageSizeOfShapes: number,
  ListOfColors: string[]
) {
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return []
  }
  ctx.globalCompositeOperation = 'color'

  const height = canvas.height
  const width = canvas.width
  const number = randomInt(averageNumberOfShapes * 2, averageNumberOfShapes / 2)
  const circles: Shape[] = []
  for (let i = 0; i < number; i++) {
    const color = ListOfColors[randomInt(ListOfColors.length)]
    const points = generateShape(ctx, width, height, averageSizeOfShapes, color)
    circles.push({ points, color })
  }
  return circles
}

function generateShape(
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
  averageSize: number,
  color: string
) {
  const centerX = randomInt(width)
  const centerY = randomInt(height)

  const numOfPoints = randomInt(15, 25)
  const noise = new ValueNoise()
  const noiseMultiplier = randomInt(5, 10)
  const allPoints: Point[] = []
  ctx.beginPath()
  for (let i = 0; i < 180; i += 180 / numOfPoints) {
    const noiseValue = noise.evalX((i / 180) * 32) - 0.5

    const value = noiseValue * noiseMultiplier + averageSize

    const pointX1 = centerX + Math.sin((i * Math.PI) / 90) * value
    const pointY1 = centerY + Math.cos((i * Math.PI) / 90) * value

    ctx.lineTo(pointX1, pointY1)
    allPoints.push({ x: pointX1, y: pointY1 })
  }

  ctx.closePath()
  ctx.fillStyle = color
  ctx.fill()
  return allPoints
}

function randomInt(max: number, min: number = 0) {
  return Math.floor(Math.random() * (max - min) + min)
}

export function reDrawCirclesMoved(
  canvas: HTMLCanvasElement,
  circles: Shape[],
  seed: string,
  time: number
) {
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return
  }
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  ctx.globalCompositeOperation = 'color'
  const noise = new ValueNoise(seed)
  circles.forEach((circle, index) => {
    const multiplier = 2000
    const timeMultiplier = 100
    const xBase = noise.evalX(index)
    const yBase = noise.evalX(index + 0.5)
    if (index === 2) {
      console.log(time, (noise.evalX(time / 500 + index) - xBase) * 50000)
    }
    const xOffset =
      (noise.evalX(time / timeMultiplier + index) - xBase) * multiplier
    const yOffset =
      (noise.evalX(time / timeMultiplier + index + 0.5) - yBase) * multiplier

    ctx.beginPath()
    circle.points.forEach((point) => {
      ctx.lineTo(point.x + xOffset, point.y + yOffset)
    })
    ctx.closePath()
    ctx.fillStyle = circle.color
    ctx.fill()
  })
}
