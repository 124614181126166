export const COLORS = [
  // '#292524', // Stone
  '#991b1b', // Red
  '#9a3412', // Orange
  '#92400e', // Amber
  '#047857', // Emerald
  '#155e75', // Cyan
  '#1e40af', // Blue
  '#86198f', // Fushia
  '#9f1239' // Rose
]

// Light
// export const COLORS = [
//   '#78716c', // Stone
//   '#ef4444', // Red
//   '#f97316', // Orange
//   '#f59e0b', // Amber
//   '#10b981', // Emerald
//   '#06b6d4', // Cyan
//   '#3b82f6', // Blue
//   '#d946ef', // Fushia
//   '#f43f5e' // Rose
// ]
